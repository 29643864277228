import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import CardGroup from 'react-bootstrap/CardGroup'

const ContactUs = () => (
    <Layout>
      <SEO title="Contact Us" />
      <Container>
          <Row>
            <Col>
            <div>
              <h2>About Us</h2>
              <p>
                Established since 1992, Logicom Instruments have been providing reliable and 
                effective industrial test solutions and services. Our extensive products and 
                services including environmental simulation test chambers, vibration test and 
                measurement equipment are used extensively in semiconductor, electronics, automotive, 
                avionics and aerospace industries.
              </p>
              <p>
              We have an experienced team whom are well trained to provide fast and customised solutions 
              based on client requirements. Our client base includes some of the leading industrial 
              manufacturers and conglomerates.
              </p>
              <p>
              We are currently an accredited calibration lab under SAC-SINGLAS certification to 
              ISO/IEC17025 Quality Standard. Cert. No. LA-2002-0247-C.
              </p>
            </div>
            </Col>
          </Row>
          <br/>
          <Row>
            <Col>
              <h3>Head Office</h3>
              <CardGroup>
                <Card style={{ 'max-width': '18rem' }}>
                <Card.Body>
                    <Card.Title>Singapore</Card.Title>
                    <Card.Text as="div">
                      <p>10 Admiralty Street #02-66/67
                      North Link Building<br/>Singapore 757695<br/>
                      Tel: (65) 6892 7666 <br/>Fax: (65) 6753 3034</p>
                      Email: sales@logicom.com.sg
                    </Card.Text>
                </Card.Body>
                </Card>
              </CardGroup>
            </Col>
          </Row>
          <br/>
          <Row>
            <Col>
              <div><h3>Regional Branches</h3></div>                  
              <CardGroup>
                <Card style={{ 'max-width': '18rem' }}>
                <Card.Body>
                    <Card.Title>Malaysia</Card.Title>
                    <Card.Text as="div">
                      Penang: (604) 626 3053<br/>
                      Email: sales.pg@logicomgroup.net
                    </Card.Text>
                </Card.Body>
                </Card>
                <Card style={{ 'max-width': '18rem' }}>
                <Card.Body>
                    <Card.Title>Philippines</Card.Title>
                    <Card.Text as="div">
                    Manila: (632) 842 5156<br/>
                    Email: sales.ph@logicomgroup.net
                    </Card.Text>
                </Card.Body>
                </Card>
                <Card style={{ 'max-width': '23rem' }}>
                <Card.Body>
                    <Card.Title>Thailand</Card.Title>
                    <Card.Text as="div">
                    Bangkok: (662) 750 0105<br/>
                    Email: sales_thailand@logicomgroup.net
                    </Card.Text>
                </Card.Body>
                </Card>
              </CardGroup>
            </Col>
          </Row>
      </Container>
    </Layout>
  )
  
  export default ContactUs